import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {NavigationExtras, Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  constructor(private router: Router) {}

  navigateToTutorials() {
    const extras: NavigationExtras = {
      fragment: 'tutorials'
    };
    this.router.navigate(['/how-to-use'], extras);
  }
}
