<main class="contacts">
  <p-toast class="custom__success-message custom__error-message"></p-toast>
  <app-contact-form (formSubmitted)="onFormSubmitted($event)"></app-contact-form>
  <section class="contacts-meeting">
    <div class="contacts-meeting__image-wrapper">
      <img class="contacts-meeting__image" src="/assets/icons/contacts-calendar.svg" alt="calendar picture">
    </div>
    <div class="contacts__text-container contacts-meeting__text-container">
      <h2 class="contacts-meeting__title contacts__title">{{ 'contact_meeting.title' | translate }}</h2>
      <div class="contacts-meeting__paragraph-wrapper">
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_1' | translate }}</p>
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_2' | translate }}</p>
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_3' | translate }}</p>
      </div>
        <button pButton type="submit" label="{{'contact_meeting.link_text' | translate}}" class="form-section__submit-button"
                (click)="navigateToLink()"> </button>
    </div>
  </section>
  <section class="contact-info">
    <div class="contact-info__title">
      <h1 class="contacts__title">{{ 'contact_card.info_title' | translate }}</h1>
    </div>
    <div class="contact-info__cards card flex justify-content-center">
      <p-card class="contact-info__card">
        <ng-template pTemplate="header">
          <img alt="Card" src="/assets/icons/icon_racoon_contact.svg" class="contact-info__card-image"/>
        </ng-template>
        <div class="contact-info__card-text">
          <h2>{{ 'contact_card.first_card_title' | translate }}</h2>
          <p class="contact-info__card-paragraph" [innerHTML]="'contact_card.first_card_desc' | translate"></p>
          <ul class="contact-info__card-list">
            <li
              class="contact-info__card-item contact-info__card-item-title">{{ 'contact_card.first_card_list_item1' | translate }}
            </li>
            <li class="contact-info__card-item contact-info__card-item-link"><i class="pi pi-phone"></i>
              <a href="tel:+48 530 947 564"
                 class="contact-info__card-link">+48 530 947 564</a></li>
            <li
              class="contact-info__card-item contact-info__card-item-title">{{ 'contact_card.first_card_list_item2' | translate }}
            </li>
            <li class="contact-info__card-item contact-info__card-item-link"><i class="pi pi-envelope"></i>
              <a href="mailto:info@email2tms.com"
                 class="contact-info__card-link">info&#64;email2tms.com</a></li>
            <li class="contact-info__card-item contact-info__card-item-title"
                [innerHTML]="'contact_card.first_card_list_item3' | translate"></li>
          </ul>
        </div>
      </p-card>
      <p-card class="contact-info__card">
        <ng-template pTemplate="header">
          <img alt="Card" src="/assets/icons/icon_data_company.svg" class="contact-info__card-image"/>
        </ng-template>
        <div class="contact-info__card-text">
          <h2>{{ 'contact_card.second_card_title' | translate }}</h2>
          <p class="contact-info__card-paragraph" [innerHTML]="'contact_card.second_card_desc' | translate"></p>
          <p class="contact-info__card-paragraph--address"
             [innerHTML]="'contact_card.second_card_address' | translate"></p>
          <span class="contact-info__card-data" [innerHTML]="'contact_card.second_card_data' | translate"></span>
        </div>
      </p-card>
    </div>
  </section>
  <section class="about-us">
    <article class="about-us__container">
      <div class="about-us__content">
        <div class="about-us__title">
          <h2 class="contacts__title">{{ 'about_us.title' | translate }}</h2>
          <h2 class="contacts__subtitle">{{ 'about_us.subtitle' | translate }}</h2>
        </div>
        <div class="contacts__text-container about-us__text">
          <p class="contacts__paragraph" [innerHTML]="'about_us.first_paragraph' | translate"></p>
          <p class="contacts__paragraph" [innerHTML]="'about_us.second_paragraph' | translate"></p>
        </div>
      </div>
      <div class="about-us__image-container"><img src="assets/icons/about-us-img.png" alt="photo our team"
                                                  class="about-us__image">
      </div>
    </article>
  </section>
  <section class="social-media">
    <div class="social-media__container">
      <div class="social-media__text">
        <h2 >{{ 'social_media.title' | translate }}</h2>
        <p class="social-media__paragraph" [innerHTML]="'social_media.description' | translate"></p>
      </div>
      <div class="social-media__icons">
        <div class="social-media__icon-container1">
          <a href="https://www.linkedin.com/company/email2tms/" class="social-media__icon">
          <img src="assets/icons/linkedin_icon.svg" alt="LinkedIn" class="social-media__icon-image">
          <p>{{ 'social_media.linkedin' | translate }}</p>
        </a></div>
        <div class="social-media__icon-container1">
          <a href="https://www.facebook.com/Email2TMS/" class="social-media__icon s-m-img1">
          <img src="assets/icons/facebook_icon.svg" alt="Facebook" class="social-media__icon-image">
          <p>{{ 'social_media.facebook' | translate }}</p>
        </a></div>
      </div>
    </div>
  </section>
</main>
