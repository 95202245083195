<footer class="footer">
  <div class="footer__container">
    <div class="footer__section footer__section--company">
      <p class="footer__copyright">{{ 'footer.copyright' | translate }}</p>
      <p class="footer__text" [innerHTML]="'footer.address' | translate"></p>
    </div>
    <div class="footer__columns">
      <div class="footer__section footer__section--documents">
        <h3 class="footer__title">{{ 'footer.forms_and_documents' | translate }}</h3>
        <ul class="footer__list">
          <li class="footer__list-item">
            <a href="../../../assets/pdf/polityka_prywatności_email2tms.pdf" target="_blank"
               class="footer__link">{{ 'footer.privacy_policy' | translate }}</a>
          </li>
          <li class="footer__list-item">
            <a href="../../../assets/pdf/polityka_plików_cookies_email2tms.pdf" target="_blank"
               class="footer__link">{{ 'footer.cookies_policy' | translate }}</a>
          </li>
          <li class="footer__list-item">
            <a href="../../../assets/pdf/regulamin_aplikacji_email2tms.pdf" target="_blank"
               class="footer__link">{{ 'footer.terms_of_service' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="footer__section footer__section--help">
        <h3 class="footer__title">{{ 'footer.help' | translate }}</h3>
        <ul class="footer__list">
          <li class="footer__list-item">
            <a href="/how-to-use"
               class="footer__link">{{ 'footer.how_to_use' | translate }}</a>
          </li>
          <li class="footer__list-item" (click)="navigateToTutorials()" style="cursor: pointer;">
            <a class="footer__link">{{ 'footer.tutorials' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="footer__section footer__section--contact">
        <h3 class="footer__title">{{ 'footer.contact' | translate }}</h3>
        <ul class="footer__list">
          <li class="footer__list-item">
            <i class="pi pi-phone"></i>
            <a href="tel:+48 530 947 564"
               class="footer__link footer__link--contact">+48 530
              947 564</a>
          </li>
          <li class="footer__list-item">
            <i class="pi pi-envelope"></i>
            <a href="mailto:info@email2tms.com"
               class="footer__link footer__link--contact">info&#64;email2tms.com</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
