import {Component, HostListener, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {NgForOf} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {ToastModule} from "primeng/toast";
import {ContactFormComponent} from "../contact-form/contact-form.component";
import {catchError, tap} from "rxjs/operators";
import {of} from "rxjs";
import {environment} from "../../../environments/environment";
import {MessageService} from "primeng/api";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [TranslateModule, NgForOf, ButtonDirective, ToastModule, ContactFormComponent, CommonModule, RouterLink, NgOptimizedImage],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  providers: [MessageService]
})
export class LandingPageComponent implements OnInit {
  public introImage: string = '';
  public secondIntroImage: string = '';
  public isMobile: boolean = false;
  cards = [
    {img: '/assets/img/landing-page/card-image1.svg', title: '1', description: 'lp.about.card_item1'},
    {img: '/assets/img/landing-page/card-image2.svg', title: '2', description: 'lp.about.card_item2'},
    {img: '/assets/img/landing-page/card-image3.svg', title: '3', description: 'lp.about.card_item3'},
    {img: '/assets/img/landing-page/card-image4.svg', title: '4', description: 'lp.about.card_item4'},
    {img: '/assets/img/landing-page/card-image5.svg', title: '5', description: 'lp.about.card_item5'},
  ];
  private apiUrl = environment.apiUrl;


  constructor(
    private translate: TranslateService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.setInitialLanguage();
    this.checkScreenSize();
    this.updateImage(this.translate.currentLang);


    this.translate.onLangChange.subscribe((event) => {
      this.updateImage(event.lang);
    });
    console.log(this.translate.currentLang);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
    this.updateImage(this.translate.currentLang);
  }

  setInitialLanguage(): void {
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = browserLang === 'pl' ? 'pl' : 'en';
    this.translate.use(defaultLang);
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  updateImage(language: string): void {
    if (language === 'en') {
      this.introImage = this.isMobile ? '/assets/img/landing-page/intro-img-en-mobile.svg' : '/assets/img/landing-page/intro-img-en.svg';
    } else {
      this.introImage = this.isMobile ? '/assets/img/landing-page/intro-img-pl-mobile.svg' : '/assets/img/landing-page/intro-img-pl.svg';
    }
    this.secondIntroImage = this.isMobile ? '/assets/img/landing-page/intro-image-2-mobile.svg' : '/assets/img/landing-page/intro-image-2.svg';
  }

  navigateToLink() {
    window.location.href = 'https://meetings-eu1.hubspot.com/bartosz-nowak';
  }

  sendFormData(formData: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiUrl, formData, { headers });
  }

  onFormSubmitted(formData: any) {
    this.sendFormData(formData).pipe(
      tap(response => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('contact.form.success_message_summary'),
          detail: this.translate.instant('contact.form.success_message_detail'),
          life: 10000
        });
      }),
      catchError(error => {
        console.error('Error sending email', error);
        return of(null);
      })
    ).subscribe();
  }
}
