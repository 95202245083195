import { Component, OnInit } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {AppTranslateModule} from "../../app-translate.module";

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css'],
  imports: [CommonModule, ButtonModule, NgOptimizedImage, AppTranslateModule]
})
export class CookieConsentComponent implements OnInit {
  showConsent: boolean = true;

  acceptCookies() {
    localStorage.setItem('cookieConsent', 'accepted');
    this.showConsent = false;
    this.enableCookies();
  }

  rejectCookies() {
    localStorage.setItem('cookieConsent', 'rejected');
    this.showConsent = false;
    this.disableCookies();
  }

  ngOnInit() {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      this.showConsent = false;
      if (consent === 'accepted') {
        this.enableCookies();
      } else {
        this.disableCookies();
      }
    }
  }

  enableCookies() {
    (window as any)['ga-disable-UA-XXXXXXX-X'] = false;
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = function () { (window as any).dataLayer.push(arguments); };
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', 'UA-XXXXXXX-X');
  }

  disableCookies() {
    (window as any)['ga-disable-UA-XXXXXXX-X'] = true;
    document.cookie.split(";").forEach((c) => {
      if (c.trim().startsWith('_ga') || c.trim().startsWith('_gid')) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      }
    });
  }
}
