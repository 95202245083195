import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimelineModule} from 'primeng/timeline';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {DialogModule} from 'primeng/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";

interface CardItem {
  headerKey: string;
  descriptionKey: string;
  imageUrl: string;
  videoUrl: string;
}

interface EventItem {
  statusKey: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
  descriptionKey: string;
}

@Component({
  selector: 'app-how-to-use',
  standalone: true,
  imports: [
    TimelineModule,
    CardModule,
    ButtonModule,
    TranslateModule,
    CommonModule,
    ScrollPanelModule,
    DialogModule
  ],
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.css'],
})
export class HowToUseComponent implements OnInit {

  events: EventItem[] = [
    {
      statusKey: 'how_to_use.start.first_element',
      icon: '01',
      color: '#F9BB00',
      descriptionKey: 'how_to_use.start.description.first_element'
    },
    {
      statusKey: 'how_to_use.start.second_element',
      icon: '02',
      color: '#F9BB00',
      descriptionKey: 'how_to_use.start.description.second_element'
    },
    {
      statusKey: 'how_to_use.start.third_element',
      icon: '03',
      color: '#F9BB00',
      descriptionKey: 'how_to_use.start.description.third_element'
    },
    {
      statusKey: 'how_to_use.start.fourth_element',
      icon: '04',
      color: '#F9BB00',
      descriptionKey: 'how_to_use.start.description.fourth_element'
    },
    {
      statusKey: 'how_to_use.start.fifth_element',
      icon: '05',
      color: '#F9BB00',
      descriptionKey: 'how_to_use.start.description.fifth_element'
    }
  ];

  cards: CardItem[] = [
    {
      headerKey: 'how_to_use.cards.card_1.header',
      descriptionKey: 'how_to_use.cards.card_1.description',
      imageUrl: 'assets/icons/preview-images/1registration.svg',
      videoUrl: 'https://www.youtube.com/embed/tBo5AzbB8jo?si=q26TCD2ZaoN7WX6e'
    },
    {
      headerKey: 'how_to_use.cards.card_2.header',
      descriptionKey: 'how_to_use.cards.card_2.description',
      imageUrl: 'assets/icons/preview-images/2configuration.svg',
      videoUrl: 'https://www.youtube.com/embed/ocUr6U4gL9w?si=yBEdRy4swLWVgx9m'
    },
    {
      headerKey: 'how_to_use.cards.card_3.header',
      descriptionKey: 'how_to_use.cards.card_3.description',
      imageUrl: 'assets/icons/preview-images/3integration.svg',
      videoUrl: 'https://www.youtube.com/embed/8vCFfYrnLHI?si=kjr8vQ4EJNRqnHZQ'
    },
    {
      headerKey: 'how_to_use.cards.card_4.header',
      descriptionKey: 'how_to_use.cards.card_4.description',
      imageUrl: 'assets/icons/preview-images/4client-panel.svg',
      videoUrl: 'https://www.youtube.com/embed/s5CE_CtGlEA?si=-QqdYL0R_DjFEjc1'
    },
    {
      headerKey: 'how_to_use.cards.card_5.header',
      descriptionKey: 'how_to_use.cards.card_5.description',
      imageUrl: 'assets/icons/preview-images/5mapping.svg',
      videoUrl: 'https://www.youtube.com/embed/IjcJOsQuWTA?si=2Y0yUAWp-WBJxU1s'
    },
    {
      headerKey: 'how_to_use.cards.card_6.header',
      descriptionKey: 'how_to_use.cards.card_6.description',
      imageUrl: 'assets/icons/preview-images/6order-statuses.svg',
      videoUrl: 'https://www.youtube.com/embed/n-g6L3I6Ois?si=3OJmm_SbJ97Dpzza'
    },
    {
      headerKey: 'how_to_use.cards.card_7.header',
      descriptionKey: 'how_to_use.cards.card_7.description',
      imageUrl: 'assets/icons/preview-images/7my-company.svg',
      videoUrl: 'https://www.youtube.com/embed/QB3j4FpB4YM?si=n3nA5-Lp4BTASXBX'
    }
  ];

  displayModal: boolean = false;
  selectedVideoUrl: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute) {
  }

  showDialog(videoUrl: string) {
      this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      this.displayModal = true;
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({behavior: 'smooth'});
          }, 300);
        }
      }
    });
  }
}
