import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenubarModule} from "primeng/menubar";
import {ButtonModule} from "primeng/button";

@NgModule({
  imports: [CommonModule, MenubarModule, ButtonModule],
  exports: [MenubarModule, ButtonModule]
})
export class PrimengModule {}
