import {Component, Output, EventEmitter, OnInit, OnDestroy, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { MessageService } from "primeng/api";
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TranslateModule} from "@ngx-translate/core";
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact-form',
  standalone: true,
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
  imports: [TranslateModule, ReactiveFormsModule, ButtonModule, CheckboxModule, CommonModule, InputTextareaModule, InputTextModule],
  providers: [MessageService]
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Input() lpClass: string = ''
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  contactForm: FormGroup;
  consentLabel: string = '';
  buttonText: string = '';
  buttonIcon: string = 'pi pi-chevron-down';
  private readonly langChangeSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      organization: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[\d\s-]+$/)]],
      questionText: ['', Validators.required],
      consent: [false, Validators.requiredTrue]
    });

    this.initTranslations();

    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initTranslations();
    });
  }

  ngOnInit() {
    this.initTranslations();
  }

  initTranslations() {
    this.translate.get('contact.form.consent_short').subscribe((res: string) => this.consentLabel = res);
    this.translate.get('button.down').subscribe((res: string) => this.buttonText = res);
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  toggleConsentText() {
    if (this.consentLabel === this.translate.instant('contact.form.consent_short')) {
      this.translate.get('contact.form.consent').subscribe((res: string) => this.consentLabel = res);
      this.translate.get('button.up').subscribe((res: string) => this.buttonText = res);
      this.buttonIcon = 'pi pi-chevron-up';
    } else {
      this.translate.get('contact.form.consent_short').subscribe((res: string) => this.consentLabel = res);
      this.translate.get('button.down').subscribe((res: string) => this.buttonText = res);
      this.buttonIcon = 'pi pi-chevron-down';
    }
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        message: `<html><body><h2>email: </h2>${this.contactForm.value.email}<br>
                             <h2>name: </h2>${this.contactForm.value.name}<br>
                             <h2>organization: </h2>${this.contactForm.value.organization}<br>
                             <h2>phone: </h2>${this.contactForm.value.phone}<br>
                             <h2>questionText: </h2>${this.contactForm.value.questionText}<br>
                             <h2>consent: </h2>${this.contactForm.value.consent.toString()}<br></body></html>`,
        subject: `Message from WWW page`
      };

      this.formSubmitted.emit(formData);
    } else {
      this.contactForm.markAllAsTouched();
    }
  }
}
