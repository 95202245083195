import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-booking-confirmed',
  standalone: true,
  imports: [
    NgOptimizedImage, TranslateModule
  ],
  templateUrl: './booking-confirmed.component.html',
  styleUrl: './booking-confirmed.component.css'
})
export class BookingConfirmedComponent {

}
