<p-carousel [value]="currentSlides" [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="10000">
  <ng-template let-slide pTemplate="item">
    <div class="slide">
      <img [src]="slide.img" alt="{{ slide.title | translate }}">
    </div>
  </ng-template>
</p-carousel>

<div class="main-container">
  <h1 class="main-title">{{ 'home.welcome_message' | translate }}</h1>
  <h2 class="sub-title" [innerHTML]="'home.what_awaits' | translate | safeHtml"></h2>
  <div class="card-content-container">
    <img ngSrc="/assets/icons/szopa_IIx3_2.png" alt="Szopa" class="background-img" height="330" width="282">
    <div class="cards-wrapper">
      <div class="card-container">
        <div class="card-image-mail">
          <img ngSrc="/assets/icons/client-email.svg" alt="Client-email" class="client-mail" height="68" width="68">
        </div>
        <div class="card-content">
          <h3>{{ 'home.mail_client' | translate }}</h3>
          <p>{{ 'home_card_descriptions.card1.description' | translate }}</p>
        </div>
      </div>
      <div class="card-container">
        <div class="card-image-digital">
          <img ngSrc="/assets/icons/digital.svg" alt="Digitalization" class="image-digital" height="68" width="68">
        </div>
        <div class="card-content">
          <h3>{{ 'home.digitalization' | translate }}</h3>
          <p>{{ 'home_card_descriptions.card2.description' | translate }}</p>
        </div>
      </div>
      <div class="card-container">
        <div class="card-image-csv">
          <img ngSrc="/assets/icons/csv.svg" alt="Document-csv" class="image-csv" height="68" width="68">
        </div>
        <div class="card-content">
          <h3>{{ 'home.csv_creation' | translate }}</h3>
          <p>{{ 'home_card_descriptions.card3.description' | translate }}</p>
        </div>
      </div>
      <div class="card-container">
        <div class="card-and-more">
          <img ngSrc="/assets/icons/and_more.svg" alt="And-more" class="and-more" height="68" width="68">
        </div>
        <div class="card-content">
          <h3>{{ 'home.and_more' | translate }}</h3>
          <p>{{ 'home_card_descriptions.card4.description' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="benefits-container">
  <div class="benefits-header">
    <h2>{{ 'home.benefits_title' | translate }}</h2>
  </div>
  <div class="benefits-cards">
    <div *ngFor="let benefit of benefits" class="benefit-card">
      <p-card>
        <ng-template pTemplate="content">
          <div class="p-card-body">
            <div class="card-image">
              <img [src]="benefit.img" alt="{{ benefit.title | translate }}">
            </div>
            <div class="card-text-content">
              <b>{{ benefit.title | translate }}</b>
              <p [innerHTML]="benefit.subtitle | translate | safeHtml"></p>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</div>

<div class="calculator-container desktop-version" *ngIf="!isMobile">
  <h1 [innerHTML]="'calculator.calculator-container-h1' | translate | safeHtml"></h1>
  <div class="calculator-content-title">{{ 'calculator.calculator-content-title' | translate }}</div>
  <div class="calculator-content">
    <div class="slider-section">
      <div class="slider-group">
        <div class="slider-label">{{ 'calculator.calculator-slider-label1' | translate }}</div>
        <div class="slider-scale">5</div>
        <p-slider [(ngModel)]="numberOfWorkers" [min]="5" [max]="50" [step]="1"></p-slider>
        <div class="slider-scale">50</div>
      </div>
      <div class="slider-group">
        <div class="slider-label">{{ 'calculator.calculator-slider-label2' | translate }}</div>
        <div class="slider-scale">1</div>
        <p-slider [(ngModel)]="ordersPerDay" [min]="1" [max]="250" [step]="1"></p-slider>
        <div class="slider-scale">250</div>
      </div>
      <div class="slider-group">
        <div class="slider-label">
          {{ 'calculator.calculator-slider-label3' | translate }}
        </div>
        <div class="slider-scale">1</div>
        <p-slider [(ngModel)]="minutesPerOrder" [min]="1" [max]="5" [step]="0.5"></p-slider>
        <div class="slider-scale">5</div>
      </div>
      <div class="slider-group">
        <div class="slider-label">
          {{ 'calculator.calculator-slider-label4' | translate }}
        </div>
        <div class="slider-scale">10</div>
        <p-slider [(ngModel)]="hourlyWage" [min]="10" [max]="30" [step]="0.5"></p-slider>
        <div class="slider-scale">30</div>
      </div>
    </div>
    <div class="results">
      <div class="result-item">
        <div class="result-value">{{ calculateSavings().monthlyHoursSaved | number:'1.0-2' }}</div>
        <div class="result-label">{{ 'calculator.calculator-result1' | translate }}</div>
      </div>
      <div class="result-item">
        <div class="result-value">{{ calculateSavings().monthlyEurosSaved | number:'1.0-2' }}</div>
        <div class="result-label">{{ 'calculator.calculator-result2' | translate }}</div>
      </div>
    </div>
  </div>
</div>

<div class="calculator-container mobile-version" *ngIf="isMobile">
  <h1 [innerHTML]="'calculator.calculator-container-h1' | translate | safeHtml"></h1>
  <div class="calculator-content">
    <div class="calculator-content-title">{{ 'calculator.calculator-content-title' | translate }}</div>
    <div class="slider-section">
      <div class="slider-group">
        <div class="slider-label">{{ 'calculator.calculator-slider-label1' | translate }}</div>
        <div class="slider-wrapper">
          <div class="slider-scale left">5</div>
          <p-slider [(ngModel)]="numberOfWorkers" [min]="5" [max]="50" [step]="1"></p-slider>
          <div class="slider-scale right">50</div>
        </div>
      </div>
      <div class="slider-group">
        <div class="slider-label">{{ 'calculator.calculator-slider-label2' | translate }}</div>
        <div class="slider-wrapper">
          <div class="slider-scale left">1</div>
          <p-slider [(ngModel)]="ordersPerDay" [min]="1" [max]="250" [step]="1"></p-slider>
          <div class="slider-scale right">250</div>
        </div>
      </div>
      <div class="slider-group">
        <p> {{ 'calculator.calculator-slider-label3' | translate }} </p>
        <div class="slider-wrapper">
          <div class="slider-scale left">1</div>
          <p-slider [(ngModel)]="minutesPerOrder" [min]="1" [max]="5" [step]="0.5"></p-slider>
          <div class="slider-scale right">5</div>
        </div>
      </div>
      <div class="slider-group">
        {{ 'calculator.calculator-slider-label4' | translate }}
        <div class="slider-wrapper">
          <div class="slider-scale left">10</div>
          <p-slider [(ngModel)]="hourlyWage" [min]="10" [max]="30" [step]="0.5"></p-slider>
          <div class="slider-scale right">30</div>
        </div>
      </div>
    </div>
    <div class="results">
      <div class="result-item">
        <div class="result-value">{{ calculateSavings().monthlyHoursSaved | number:'1.0-2' }}</div>
        <div class="result-label">{{ 'calculator.calculator-result1' | translate }}</div>
      </div>
      <div class="result-item">
        <div class="result-value">{{ calculateSavings().monthlyEurosSaved | number:'1.0-2' }}</div>
        <div class="result-label">{{ 'calculator.calculator-result2' | translate }}</div>
      </div>
    </div>
  </div>
</div>

<div class="faq-section">
  <h2>{{ 'faq-section.h2' | translate }}</h2>
  <p-accordion>
    <p-accordionTab *ngFor="let faq of faqs; let i = index" class="faq-item" [selected]="isActive(i)">
      <ng-template pTemplate="header">
        <div class="faq-header" (click)="toggleAccordion(i, $event)">
          <span>{{ faq.header | translate }}</span>
          <i class="faq-icon pi" [ngClass]="{'pi-plus': !isActive(i), 'pi-minus': isActive(i)}"></i>
        </div>
      </ng-template>
      <div class="faq-content" (click)="toggleAccordion(i, $event)">
        <p [innerHTML]="faq.content | translate | safeHtml"></p>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<div class="section">
  <div class="image-container-parent">
    <div class="image-container-child">
      <div class="image">
        <img src="/assets/icons/szopik.svg" alt="Szopik" class="szop-science">
      </div>
    </div>
    <div class="frame-parent">
      <div class="title-wrapper">
        <div class="title">
          <p>{{ 'home.last_section.title' | translate }}</p>
        </div>
      </div>
      <div class="title-container">
        <div class="title1">{{ 'home.last_section.title1' | translate }}</div>
      </div>
      <div class="button-secondary-parent unique-section-buttons">
        <button pButton [label]="'home.last_section.buttons.button1' | translate" class="p-section-button-secondary" (click)="navigateToHowUse()"></button>
        <button pButton [label]="'home.last_section.buttons.button2' | translate" class="p-section-button-secondary" (click)="navigateToTutorials()"></button>
      </div>
    </div>
  </div>
</div>
