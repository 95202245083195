import {Component, HostListener, OnInit} from '@angular/core';
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { PanelModule } from "primeng/panel";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import { DividerModule } from "primeng/divider";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SafeHtmlPipe} from "../../safe-html.pipe";
import {SliderModule} from "primeng/slider";
import {FormsModule} from "@angular/forms";
import {AccordionModule} from "primeng/accordion";
import {NavigationExtras, Router, RouterLink} from "@angular/router";
import {CookieConsentComponent} from "../cookie-consent/cookie-consent.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CarouselModule, ButtonModule, CardModule, PanelModule, TranslateModule,
    DividerModule, NgOptimizedImage, SafeHtmlPipe, SliderModule, FormsModule,
    AccordionModule, CommonModule, RouterLink, CookieConsentComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private router: Router, private translateService: TranslateService) {}
  numberOfWorkers = 5;
  ordersPerDay = 20;
  minutesPerOrder = 1.5;
  hourlyWage = 10;
  isMobile = false;
  activeIndexes: number[] = [];

  faqs = [
    { header: 'faq-section.accordion_tab_header0', content: 'faq-section.faq-content0' },
    { header: 'faq-section.accordion_tab_header1', content: 'faq-section.faq-content1' },
    { header: 'faq-section.accordion_tab_header2', content: 'faq-section.faq-content2' },
    { header: 'faq-section.accordion_tab_header3', content: 'faq-section.faq-content3' },
    { header: 'faq-section.accordion_tab_header4', content: 'faq-section.faq-content4' }
  ];

  slidesDesktop = [
    { img: './assets/slide/desktop-pl/slide_1.svg', title: 'home_slides.slide1.title', description: 'home_slides.slide1.description' },
    { img: './assets/slide/desktop-pl/slide_2.svg', title: 'home_slides.slide2.title', description: 'home_slides.slide2.description' },
    { img: './assets/slide/desktop-pl/slide_3.svg', title: 'home_slides.slide3.title', description: 'home_slides.slide3.description' },
    { img: './assets/slide/desktop-pl/slide_4.svg', title: 'home_slides.slide4.title', description: 'home_slides.slide4.description' }
  ];

  slidesDesktopEnglish = [
    { img: './assets/slide/desktop-en/slide_1_english.svg', title: 'home_slides.slide1.title', description: 'home_slides.slide1.description' },
    { img: './assets/slide/desktop-en/slide_2_english.svg', title: 'home_slides.slide2.title', description: 'home_slides.slide2.description' },
    { img: './assets/slide/desktop-en/slide_3_english.svg', title: 'home_slides.slide3.title', description: 'home_slides.slide3.description' },
    { img: './assets/slide/desktop-en/slide_4_english.svg', title: 'home_slides.slide4.title', description: 'home_slides.slide4.description' }
  ];

  slidesMobile = [
    { img: './assets/slide/mobile-pl/slide_1_mobile.svg', title: 'home_slides.slide1.title', description: 'home_slides.slide1.description' },
    { img: './assets/slide/mobile-pl/slide_2_mobile.svg', title: 'home_slides.slide2.title', description: 'home_slides.slide2.description' },
    { img: './assets/slide/mobile-pl/slide_3_mobile.svg', title: 'home_slides.slide3.title', description: 'home_slides.slide3.description' },
    { img: './assets/slide/mobile-pl/slide_4_mobile.svg', title: 'home_slides.slide4.title', description: 'home_slides.slide4.description' }
  ];

  slidesMobileEnglish = [
    { img: './assets/slide/mobile-en/slide_1_mobile_eng.svg', title: 'home_slides.slide1.title', description: 'home_slides.slide1.description' },
    { img: './assets/slide/mobile-en/slide_2_mobile_eng.svg', title: 'home_slides.slide2.title', description: 'home_slides.slide2.description' },
    { img: './assets/slide/mobile-en/slide_3_mobile_eng.svg', title: 'home_slides.slide3.title', description: 'home_slides.slide3.description' },
    { img: './assets/slide/mobile-en/slide_4_mobile_eng.svg', title: 'home_slides.slide4.title', description: 'home_slides.slide4.description' }
  ];

  currentSlides = this.slidesDesktop;

  ngOnInit() {
    this.checkScreenSize();
    this.updateSlides(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((event) => {
      this.updateSlides(event.lang);
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 992;
    this.updateSlides(this.translateService.currentLang);
  }

  updateSlides(language: string) {
    if (language === 'en') {
      this.currentSlides = this.isMobile ? this.slidesMobileEnglish : this.slidesDesktopEnglish;
    } else {
      this.currentSlides = this.isMobile ? this.slidesMobile : this.slidesDesktop;
    }
  }

  benefits = [
    { img: './assets/benefits/benefit_1.svg', title: 'home_benefits_cards.title.card1', subtitle: 'home_benefits_cards.subtitle.card1' },
    { img: './assets/benefits/benefit_2.svg', title: 'home_benefits_cards.title.card2', subtitle: 'home_benefits_cards.subtitle.card2' },
    { img: './assets/benefits/benefit_3.svg', title: 'home_benefits_cards.title.card3', subtitle: 'home_benefits_cards.subtitle.card3' },
    { img: './assets/benefits/benefit_4.svg', title: 'home_benefits_cards.title.card4', subtitle: 'home_benefits_cards.subtitle.card4' },
    { img: './assets/benefits/benefit_5.svg', title: 'home_benefits_cards.title.card5', subtitle: 'home_benefits_cards.subtitle.card5' },
    { img: './assets/benefits/benefit_6.svg', title: 'home_benefits_cards.title.card6', subtitle: 'home_benefits_cards.subtitle.card6' }
  ];

  calculateSavings() {
    const monthlyHoursSaved = (this.numberOfWorkers * this.ordersPerDay * this.minutesPerOrder * 0.8 / 60) * 243 / 12;
    const baseCost = Math.min(this.numberOfWorkers, 5) * 59;
    const additionalCost = Math.max(this.numberOfWorkers - 5, 0) * 45;
    const totalCost = baseCost + additionalCost;
    const monthlyEurosSaved = monthlyHoursSaved * (this.hourlyWage * 1.15) - totalCost;
    return {
      monthlyHoursSaved: Math.round(monthlyHoursSaved),
      monthlyEurosSaved: Math.round(monthlyEurosSaved)
    };
  }

  isActive(index: number): boolean {
    return this.activeIndexes.includes(index);
  }

  navigateToHowUse() {
    this.router.navigate(['/how-to-use']);
  }

  navigateToTutorials() {
    const extras: NavigationExtras = {
      fragment: 'tutorials'
    };
    this.router.navigate(['/how-to-use'], extras);
  }

  toggleAccordion(index: number, event: Event) {
    event.stopPropagation();
    const activeIndexPos = this.activeIndexes.indexOf(index);
    if (activeIndexPos !== -1) {
      this.activeIndexes.splice(activeIndexPos, 1);
    } else {
      this.activeIndexes.push(index);
    }
  }

}
