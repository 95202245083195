<main class="how-to-use-main">
  <section class="how-to-use-section">
    <div class="how-to-start-content">
      <h2 class="how-to-use__title" [innerHTML]=" 'how_to_use.start.title' | translate"></h2>
      <div class="how-to-use__card">
        <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
          <ng-template pTemplate="marker" let-event>
            <span class="marker" [ngStyle]="{ 'background-color': event.color }">{{ event.icon }}</span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <p-card [header]="event.statusKey | translate" [subheader]="event.date">
              <img *ngIf="event.image" [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image"
                   [alt]="event.name" width="200" class="shadow-2"/>
              <p [innerHTML]="event.descriptionKey | translate" class="text-left"></p>
            </p-card>
          </ng-template>
        </p-timeline>
      </div>
    </div>
    <div class="first-steps-content" id="tutorials">
      <article class="first-steps-article">
        <img src="assets/icons/racoon-cam.svg" height="279" width="275" alt="racoon"/>
        <h3 class="how-to-use__title" >{{ 'how_to_use.first_steps.title' | translate }}</h3>
        <p [innerHTML]=" 'how_to_use.first_steps.paragraph_1' | translate"></p>
        <p [innerHTML]=" 'how_to_use.first_steps.paragraph_2' | translate"></p>
        <p [innerHTML]=" 'how_to_use.first_steps.paragraph_3' | translate"></p>
      </article>
      <div class="card first-steps-card-scroll">
        <p-scrollPanel [style]="{ width: '40rem', height: '41rem' }">
          <ng-container *ngFor="let card of cards">
            <div class="card flex justify-content-center first-steps-card-item">
              <p-card class="first-steps-card-content" (click)="showDialog(card.videoUrl)">
                <div class="image-container">
                  <img [src]="card.imageUrl" alt="Video Image" class="card-image" (click)="showDialog(card.videoUrl)"/>
                  <img src="assets/icons/custom-play-icon.svg" alt="Play Icon" class="play-icon" (click)="showDialog(card.videoUrl)"/>
                  <!--                  <i class="pi pi-play-circle play-icon" (click)="showDialog(card.videoUrl)"></i>-->
                </div>
                <div class="card-content-text">
                  <h3 class="header">{{ card.headerKey | translate }}</h3>
                  <p class="m-0 description" [innerHTML]="card.descriptionKey | translate"></p>
                </div>
              </p-card>
            </div>
          </ng-container>
        </p-scrollPanel>
      </div>
    </div>
  </section>
  <section>
    <section class="documents-section">
      <div class="documents-container">
        <div class="documents-text">
          <h2>{{ 'how_to_use.documents.title' | translate }}</h2>
          <p>{{ 'how_to_use.documents.description' | translate }}</p>
        </div>
        <div class="documents-icons">
          <a target="_blank" class="doc-icon">
            <div class="doc-icon-wrapper">
              <img src="assets/icons/iconDocs.svg" alt="Document Icon" class="doc-icon-img">
              <span class="icon-overlay">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32"
                     fill="none">
                  <path
                    d="M4.02666 7.9008L15.349 3.07323L26.6713 7.9008C27.0364 8.05633 27.2344 8.38605 27.2282 8.6971C27.2034 14.3832 24.8523 24.2063 15.7017 28.6109C15.4789 28.7166 15.2191 28.7166 15.0025 28.6109C5.84566 24.2001 3.50076 14.3832 3.46982 8.69088C3.46982 8.37983 3.66162 8.05633 4.02666 7.89458V7.9008ZM27.8283 5.15107L16.1781 0.180412C15.9182 0.0622111 15.6398 0 15.349 0C15.0582 0 14.7798 0.0622111 14.5199 0.180412L2.86968 5.15107C1.50852 5.72964 0.493841 7.07962 0.500028 8.70955C0.530963 14.8809 3.05529 26.1722 13.7156 31.3046C14.7489 31.8023 15.9491 31.8023 16.9824 31.3046C27.6489 26.1722 30.167 14.8809 30.198 8.70955C30.2042 7.07962 29.1895 5.72964 27.8283 5.15107ZM15.349 7.96301C14.5261 7.96301 13.8641 8.62867 13.8641 9.45608V16.4237C13.8641 17.2511 14.5261 17.9168 15.349 17.9168C16.1719 17.9168 16.8339 17.2511 16.8339 16.4237V9.45608C16.8339 8.62867 16.1719 7.96301 15.349 7.96301ZM17.3289 21.8983C17.3289 21.3703 17.1203 20.864 16.749 20.4906C16.3777 20.1173 15.8741 19.9075 15.349 19.9075C14.8239 19.9075 14.3203 20.1173 13.949 20.4906C13.5777 20.864 13.3691 21.3703 13.3691 21.8983C13.3691 22.4263 13.5777 22.9326 13.949 23.306C14.3203 23.6793 14.8239 23.889 15.349 23.889C15.8741 23.889 16.3777 23.6793 16.749 23.306C17.1203 22.9326 17.3289 22.4263 17.3289 21.8983Z"
                    fill="white"/>
                </svg>
              </span></div>
            <p>{{ 'how_to_use.documents.first_doc' | translate }}</p>
          </a>
          <a href="../../../assets/pdf/polityka_prywatności_email2tms.pdf" target="_blank" class="doc-icon">
            <img src="assets/icons/iconDocs.svg" alt="Document Icon" class="doc-icon-img">
            <p>{{ 'how_to_use.documents.second_doc' | translate }}</p>
          </a>
          <a href="../../../assets/pdf/regulamin_aplikacji_email2tms.pdf" target="_blank" class="doc-icon">
            <img src="assets/icons/iconDocs.svg" alt="Document Icon" class="doc-icon-img">
            <p>{{ 'how_to_use.documents.third_doc' | translate }}</p>
          </a>
        </div>
      </div>
    </section>
  </section>
  <!-- Modal window for YouTube player -->
  <p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '85vw'}" class="how-to-use__dialog" [closable]="true"
            [dismissableMask]="true">
    <ng-container *ngIf="selectedVideoUrl">
      <div class="video-container">
        <iframe width="100%" height="315" [src]="selectedVideoUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </ng-container>
  </p-dialog>
</main>
<!--[style]="{width: '50vw'}"-->
