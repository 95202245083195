<main class="lp__main">
  <article class="lp__section lp__section-intro">
    <figure class="lp__intro-img-wrapper">
      <img [src]="introImage" alt="Intro image" class="lp__intro-img">
    </figure>
    <section class="lp__intro-content">
      <div class="lp__intro-content__img-wrapper">
        <img [src]="secondIntroImage" alt="Second intro image" class="lp__intro-img">
      </div>
      <div class="lp__intro-content__article">
        <h2 class="lp__intro-subtitle lp__subtitle">{{ 'lp.intro.subtitle' | translate }}</h2>
        <p [innerHTML]=" 'lp.intro.paragraph1' | translate "></p>
        <ul>
          <li [innerHTML]=" 'lp.intro.li_item1' | translate"></li>
          <li [innerHTML]=" 'lp.intro.li_item2' | translate"></li>
          <li [innerHTML]=" 'lp.intro.li_item3' | translate"></li>
          <li [innerHTML]=" 'lp.intro.li_item4' | translate"></li>
        </ul>
        <p>{{ 'lp.intro.paragraph2' | translate }}</p>
      </div>
    </section>
  </article>
  <article class="lp__section lp__section-about ">
    <h2 class="lp__about-subtitle lp__subtitle text-center">
      {{ 'lp.about.subtitle' | translate }}</h2>
    <div class="lp__about-cards-wrapper text-center">
      <div *ngFor="let card of cards" class="lp__about-card text-center">
        <div class="lp__about-card-wrapper">
          <div class="lp__card__title">{{ card.title | translate }}</div>
          <p class="lp__card__description">{{ card.description | translate }}</p>
          <div class="lp__card-img-wrapper">
            <img [src]="card.img" alt="Card image screenshots from application" class="lp__card-img">
          </div>
        </div>
      </div>
    </div>
  </article>
  <section class="lp__section lp__section-lets-meet">
    <div class="lp__lets-meet-image-wrapper">
      <img class="lp__lets-meet-image lp__intro-img" src="/assets/icons/contacts-calendar.svg" alt="calendar picture">
    </div>
    <div class="lp__lets-meet-container lp__lets-meet-text-container">
      <h2 class="lp__lets-meet-title lp__subtitle text-center">{{ 'contact_meeting.title' | translate }}</h2>
      <div class="lp__lets-meet-paragraph-wrapper">
        <p class="lp__lets-meet-paragraph text-center">{{ 'contact_meeting.paragraph_1' | translate }}</p>
      </div>
      <button pButton type="submit" label="{{'contact_meeting.link_text' | translate}}"
              class="form-section__submit-button lp__lets-meet-button"
              (click)="navigateToLink()"></button>
    </div>
  </section>
  <section class="lp__section lp__section-form">
    <p-toast class="custom__success-message custom__error-message"></p-toast>
    <app-contact-form (formSubmitted)="onFormSubmitted($event)"
                      [lpClass]=" 'lp__form-section' "></app-contact-form>
  </section>
  <section class="lp__section lp__section-logo">
    <div class="text-center lp__logo-wrapper">
      <h3 class="lp__logo-title">{{ 'lp.logo.title' | translate }}</h3>
      <p>{{ 'lp.logo.paragraph' | translate }}</p>
      <div class="lp__logo-img-wrapper">
        <a routerLink="/home">
          <img src="assets/Logo-Design-v1x3 5.svg" alt="Logo image" class="lp__logo-img">
        </a>
      </div>
    </div>
  </section>
</main>
<footer class="lp__footer">
  <div class=" lp__footer-container">
    <div class=" footer__section--company">
      <p class="footer__copyright lp__footer-copyright">{{ 'footer.copyright' | translate }}</p>
      <p class="footer__text" [innerHTML]="'footer.address' | translate"></p>
    </div>
    <div class=" footer__section--contact lp__footer-contact">
      <h3 class="footer__title ">{{ 'footer.contact' | translate }}</h3>
      <ul class="footer__list lp__footer__list">
        <li class="footer__list-item">
          <i class="pi pi-phone"></i>
          <a href="tel:+48 530 947 564"
             class="footer__link footer__link--contact">+48 530
            947 564</a>
        </li>
        <li class="footer__list-item">
          <i class="pi pi-envelope"></i>
          <a href="mailto:info@email2tms.com"
             class="footer__link footer__link--contact">info&#64;email2tms.com</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
