import { Component, OnInit } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { MenuItem } from "primeng/api";
import { PrimengModule } from "../../primeng.module";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { AppTranslateModule } from "../../app-translate.module";
import { Location } from '@angular/common';

interface Language {
  name: string;
  code: string;
  icon: string;
}

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    PrimengModule,
    DropdownModule,
    FormsModule,
    AppTranslateModule,
    NgClass,
    NgForOf,
    NgIf
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  isMobileMenuOpen = false;
  isDropdownOpen = false;
  items: MenuItem[] = [];
  languages: Language[] = [
    { name: 'Polski', code: 'pl', icon: 'assets/icons/pl.png' },
    { name: 'English', code: 'en', icon: 'assets/icons/en.svg' }
  ];
  selectedLanguage: Language = this.languages[0];

  constructor(private translate: TranslateService, private router: Router, private location: Location) {
    this.translate.onLangChange.subscribe(() => {
      this.loadMenuItems();
    });
  }

  ngOnInit() {
    this.initializeLanguage();
    this.translate.use(this.selectedLanguage.code).subscribe(() => {
      this.loadMenuItems();
    });
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  loadMenuItems() {
    this.translate.get([
      'navbar.home',
      'navbar.how_to_use',
      'navbar.contact',
      'navbar.login'
    ]).subscribe(translations => {
      this.items = [
        { label: translations['navbar.home'], routerLink: ['/home'] },
        { label: translations['navbar.how_to_use'], routerLink: ['/how-to-use'] },
        { label: translations['navbar.contact'], routerLink: ['/contact'] }
      ];
    });
  }

  switchLanguage(languageCode: string): void {
    this.translate.use(languageCode);
    localStorage.setItem('language', languageCode);
    this.selectedLanguage = this.languages.find(lang => lang.code === languageCode) || this.languages[0];

    document.querySelectorAll('.language-button').forEach(button => {
      button.classList.remove('selected');
    });
    const selectedButton = document.querySelector(`.language-button[data-lang="${languageCode}"]`);
    if (selectedButton) {
      selectedButton.classList.add('selected');
    }

    this.closeDropdown();
    this.toggleMobileMenu();
  }

  initializeLanguage(): void {
    const languageCode = localStorage.getItem('language') || 'pl';
    this.translate.setDefaultLang('pl');
    this.translate.use(languageCode);
    this.selectedLanguage = this.languages.find(lang => lang.code === languageCode) || this.languages[0];

    setTimeout(() => {
      const selectedButton = document.querySelector(`.language-button[data-lang="${languageCode}"]`);
      if (selectedButton) {
        selectedButton.classList.add('selected');
      }
    });
  }

  navigateToHome() {
    if (this.location.path() === '/home') {
      window.location.reload();
    } else {
      this.router.navigate(['/home']).then(() => {
        console.log('Navigation to /home was successful!');
        this.toggleMobileMenu();
      }).catch((error) => {
        console.error('Navigation to /home failed!', error);
      });
    }
  }

  openExternalPage() {
    window.open('https://e2t-app.platform.email2tms.com/', '_blank');
    this.toggleMobileMenu();
  }

  openDropdown() {
    this.isDropdownOpen = true;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }
}
