import {Component, OnDestroy} from '@angular/core';
import {HttpClientModule, HttpClient, HttpHeaders} from "@angular/common/http";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextareaModule} from "primeng/inputtextarea";
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {CheckboxModule} from "primeng/checkbox";
import {TranslateModule} from "@ngx-translate/core";
import {CardModule} from "primeng/card";
import {Subscription} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {environment} from '../../../environments/environment';
import {ContactFormComponent} from "../contact-form/contact-form.component";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    TranslateModule,
    CheckboxModule,
    HttpClientModule,
    ToastModule,
    ContactFormComponent
  ],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [MessageService]
})

export class ContactComponent implements OnDestroy {
  consentLabel: string = '';
  buttonText: string = '';
  private readonly langChangeSubscription: Subscription;
  private apiUrl = environment.apiUrl;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.initTranslations();

    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initTranslations();
    });
  }

  initTranslations() {
    this.translate.get('contact.form.consent_short').subscribe((res: string) => this.consentLabel = res);
    this.translate.get('button.down').subscribe((res: string) => this.buttonText = res);
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
  navigateToLink() {
    window.location.href = 'https://meetings-eu1.hubspot.com/bartosz-nowak';
  }

  sendFormData(formData: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiUrl, formData, { headers });
  }

  onFormSubmitted(formData: any) {
    this.sendFormData(formData).pipe(
      tap(response => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('contact.form.success_message_summary'),
          detail: this.translate.instant('contact.form.success_message_detail'),
          life: 10000
        });
      }),
      catchError(error => {
        console.error('Error sending email', error);
        return of(null);
      })
    ).subscribe();
  }
}
