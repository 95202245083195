<main class="booking-confirmed">
  <section class="booking-confirmed__section">
    <article class="booking-confirmed__article">
      <figure class="booking-confirmed__figure">
        <img class="booking-confirmed__image" src="assets/icons/racoon-calendar.svg" alt="">
      </figure>

      <h2 class="booking-confirmed__title">{{ 'booking_confirmed.title' | translate }}</h2>
      <p class="booking-confirmed__paragraph">{{ 'booking_confirmed.paragraph' | translate }}</p>
    </article>
    <footer class="booking-confirmed__footer">
      <p class="booking-confirmed__footer-text booking-confirmed__paragraph">{{ 'booking_confirmed.footer_text' | translate }}</p>
      <a class="booking-confirmed__home-link" href="/home">
        <img class="booking-confirmed__home-logo" src="assets/Logo-Design-v1x3%205.svg" alt="">
      </a>
    </footer>
  </section>
</main>

