<ng-container *ngIf="!isBookingConfirmedPage && !isLandingPage" >
  <app-navigation></app-navigation>
</ng-container>

<app-cookie-consent></app-cookie-consent>

<router-outlet></router-outlet>

<ng-container *ngIf="!isBookingConfirmedPage && !isLandingPage" >
  <app-footer></app-footer>
</ng-container>
