<div [ngClass]="{'menubar-container': true, 'menubar-container-mobile-open': isMobileMenuOpen}">
  <p-menubar>
    <ng-template pTemplate="start">
      <div class="start-container">
        <a routerLink="/home" (click)="navigateToHome(); toggleMobileMenu()" class="logo-container">
          <img ngSrc="assets/Logo-Design-v1x3 5.svg" alt="Logo" class="logo" height="30" width="234" priority>
        </a>
        <div class="menu-items">
          <a *ngFor="let item of items; let i = index" [routerLink]="item.routerLink" class="menu-item" [ngClass]="{'first-item': i === 0}" (click)="toggleMobileMenu()">{{item.label}}</a>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="end">
      <div class="end-container">
        <button pButton [label]="'navbar.login' | translate" class="login-button" (click)="openExternalPage()"></button>
        <p-dropdown
          [options]="languages"
          [(ngModel)]="selectedLanguage"
          (onChange)="switchLanguage(selectedLanguage.code)"
          (onShow)="openDropdown()"
          (onHide)="closeDropdown()">
          <ng-template pTemplate="dropdownicon">
            <i class="pi" [ngClass]="isDropdownOpen ? 'pi-chevron-up' : 'pi-chevron-down'" style="font-size: 1rem;" (click)="openDropdown()"></i>
          </ng-template>
          <ng-template let-lang pTemplate="item">
            <div class="language-item">
              <img [ngSrc]="lang.icon" alt="{{lang.name}} flag" height="16" width="26" class="flag-icon" style="border-radius: 4px; box-shadow: 0 1px 5.7px rgba(0, 0, 0, 0.3)">
              <span class="language-name">{{lang.name}}</span>
            </div>
          </ng-template>
          <ng-template let-selectedItem pTemplate="selectedItem">
            <img [ngSrc]="selectedItem.icon" alt="{{selectedItem.name}} flag" height="16" width="26" style="border-radius: 4px; box-shadow: 0 1px 5.7px rgba(0, 0, 0, 0.3)">
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
  </p-menubar>
  <button class="mobile-menu-button" (click)="toggleMobileMenu()">
    <i class="pi" [ngClass]="isMobileMenuOpen ? 'pi-times' : 'pi-bars'"></i>
  </button>
</div>

<div [ngClass]="{'mobile-menu': true, 'mobile-menu-open': isMobileMenuOpen}">
  <a *ngFor="let item of items" [routerLink]="item.routerLink" class="menu-item" (click)="toggleMobileMenu()">{{item.label}}</a>
  <button pButton [label]="'navbar.login' | translate" class="login-button" (click)="openExternalPage()"></button>
  <div class="language-buttons">
    <button pButton class="language-button" data-lang="pl" (click)="switchLanguage('pl')">
      <img ngSrc="assets/icons/pl.png" alt="Polish flag" height="16" width="24">
      Polski
    </button>
    <button pButton class="language-button" data-lang="en" (click)="switchLanguage('en')">
      <img ngSrc="assets/icons/en.svg" alt="English flag" height="16" width="24">
      English
    </button>
  </div>
</div>
