<div *ngIf="showConsent" class="cookie-consent">
  <img class="cookie-icon" alt="Cookie Icon" src="/assets/icons/cookie.svg">
  <div class="content">
    <p class="consent-text">
      {{ 'cookie' | translate }}
      <a href="/how-to-use" target="_blank">{{ 'privacy_policy' | translate }}</a>.
      {{ 'consent_statement' | translate }}
    </p>
    <div class="actions">
      <button pButton type="button" label="{{ 'reject' | translate }}" class="p-button-secondary" (click)="rejectCookies()"></button>
      <button pButton type="button" label="{{ 'accept' | translate }}" class="p-button-primary" (click)="acceptCookies()"></button>
    </div>
  </div>
  <div class="close-icon" (click)="rejectCookies()">
    <img class="times-icon" alt="Close Icon" src="/assets/icons/close.svg">
  </div>
</div>
