<section class="form-section" [ngClass]="lpClass">
  <div class="form-section__title-container">
    <h2 class="contacts__title">{{ 'contact.title' | translate }}</h2>
    <h3 class="contacts__subtitle" [innerHTML]="'contact.title2' | translate"></h3>
  </div>
  <form [formGroup]="contactForm" class="form-section__form card flex justify-content-center" (ngSubmit)="onSubmit()">
    <p class="form-section__paragraph" [innerHTML]="'contact.form.title' | translate"></p>
    <div class="form-section__row">
      <div class="form-section__group form-section__group--half">
        <label for="name">{{ 'contact.form.name' | translate }}</label>
        <input pInputText type="text" id="name" formControlName="name" required
               placeholder="{{'contact_placeholder.form.name_title' | translate}}" class="form-section__group-input"/>
        <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="form-section__error">
          {{ 'contact.form.name_error' | translate }}
        </div>
      </div>
      <div class="form-section__group form-section__group--half">
        <label for="organization">{{ 'contact.form.company' | translate }}</label>
        <input pInputText type="text" id="organization" formControlName="organization" required
               placeholder="{{'contact_placeholder.form.company_title' | translate}}"
               class="form-section__group-input"/>
        <div *ngIf="contactForm.get('organization')?.invalid && contactForm.get('organization')?.touched"
             class="form-section__error">
          {{ 'contact.form.company_error' | translate }}
        </div>
      </div>
    </div>
    <div class="form-section__row">
      <div class="form-section__group form-section__group--half">
        <label for="email">{{ 'contact.form.email' | translate }}</label>
        <input pInputText type="email" id="email" formControlName="email" required
               placeholder="{{'contact_placeholder.form.email_title' | translate}}"
               class="form-section__group-input"/>
        <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
             class="form-section__error">
          {{ 'contact.form.email_error' | translate }}
        </div>
      </div>
      <div class="form-section__group form-section__group--half">
        <label for="phone">{{ 'contact.form.phone' | translate }}</label>
        <input pInputText type="tel" id="phone" formControlName="phone" required
               placeholder="{{'contact_placeholder.form.phone_title' | translate}}"
               class="form-section__group-input"/>
        <div *ngIf="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched"
             class="form-section__error">
          {{ 'contact.form.phone_error' | translate }}
        </div>
      </div>
    </div>
    <div class="form-section__group">
      <label for="text">{{ 'contact.form.text' | translate }}</label>
      <textarea pInputTextarea id="text" rows="1" formControlName="questionText" autoResize="true" required
                placeholder="{{'contact_placeholder.form.text_title' | translate}}"
                class="form-section__group-input"></textarea>
      <div *ngIf="contactForm.get('questionText')?.invalid && contactForm.get('questionText')?.touched"
           class="form-section__error">
        {{ 'contact.form.text_error' | translate }}
      </div>
    </div>
    <div class="form-section__group form-section__group--consent">
      <div class="form-section__consent" id="rodo">
        <div class="p-field-checkbox">
          <p-checkbox formControlName="consent" [binary]="true" inputId="consent-checkbox" id="box"></p-checkbox>
          <div class="form-section__consent-text">
            <span>{{ consentLabel }}</span>
          </div>
        </div>
      </div>
      <div class="form-section__consent-button">
        <button pButton type="button" icon="{{buttonIcon}}" label="{{buttonText}}" (click)="toggleConsentText()"
                class="form-section__toggle-button"></button>
      </div>
      <div *ngIf="contactForm.get('consent')?.invalid && contactForm.get('consent')?.touched"
           class="form-section__error form-section__error-consent">
        {{ 'contact.form.consent_error' | translate }}
      </div>
    </div>
    <button pButton type="submit" label="{{'contact.form.submit' | translate}}" class="form-section__submit-button"
            [disabled]="!contactForm.valid"  ></button>
  </form>
</section>
